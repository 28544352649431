import React from "react"
import { Avatar } from "../assets/icons"
import { useTranslation, Trans } from "gatsby-plugin-react-i18next"
import { graphql } from "gatsby"

export default function About() {
  const { t } = useTranslation()

  const items = [
    {
      id: 1,
      image: <Avatar width="60" height="60" />,
      name: "Product",
      role: t("about.team.ceo"),
    },
    {
      id: 2,
      image: <Avatar width="60" height="60" />,
      name: "Product",
      role: t("about.team.cto"),
    },
    {
      id: 3,
      image: <Avatar width="60" height="60" />,
      name: "Product",
      role: t("about.team.coo"),
    },
    {
      id: 4,
      image: <Avatar width="60" height="60" />,
      name: "Design",
      role: t("about.team.design"),
    },
    {
      id: 5,
      image: <Avatar width="60" height="60" />,
      name: "Business",
      role: t("about.team.business"),
    },
    {
      id: 6,
      image: <Avatar width="60" height="60" />,
      name: "Product",
      role: t("about.team.support"),
    },
  ]

  return (
    <div className="flex flex-col items-center justify-center pt-2">
      <div className="max-w-md">
        <div className="py-2">
          <p className="text-md uppercase pb-2">
            <strong>{t("about.story.title")}</strong>
          </p>
          <p className="text-xs uppercase max-w-md">
            <Trans i18nKey="about.story.paragraph1">
              Starting small, we've steadily grown by embracing{" "}
              <strong>innovation</strong>. We focus on using advanced technology
              to create impactful solutions in the rapidly evolving 'IT' sector,
              addressing current challenges while preparing for future demands.
            </Trans>
          </p>
          <p className="text-xs uppercase max-w-md py-4">
            <Trans i18nKey="about.story.paragraph2">
              Committed to excellence, we proudly uphold the{" "}
              <strong>'Made in Italy'</strong> standard, delivering scalable,
              secure solutions that drive digital and virtual transformation
              globally.
            </Trans>
          </p>
        </div>
      </div>
      <div className="pt-2 w-full max-w-md">
        <p className="text-md uppercase py-2">
          <strong>{t("about.sub")}</strong>
        </p>
        <div className="grid grid-cols-3 gap-4 py-2">
          {items.map(item => (
            <div
              key={item.id}
              className="flex flex-col text-center justify-center"
            >
              <div className=" mb-2 rounded-2xl">{item.image}</div>
              <p className="text-xs uppercase">
                <b>{item.role}</b>
              </p>
              <p className="text-xxs uppercase">{item.name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
